import { useState } from "react";
import { Button } from "../../../../../../components/Button";
import { Icons } from "../../../../../../components/Icons";
import { SubHeader } from "../../../../../../components/SubHeader";
import { Table } from "../../../../../../components/Table";
import { Alert } from "../../../../../../styles";
import { CreateEditChecklist } from "../CreateEditChecklist";

import { base64Icons } from "../../../../../../utils/globalData";
import { api } from "../../../../../../services/api";
import { jsPDF } from "jspdf";
import {
  convertDateToString,
  convertNumberToString,
  getAverage,
  getBase64FromUrl,
  getStandartDeviation,
} from "../../../../../../utils/globalFunctions";
import autoTable from "jspdf-autotable";
import Swal from "sweetalert2";

export function ListChecklists(props) {
  const [loadingReport, setloadingReport] = useState([]);

  const [progressReport, setProgressReport] = useState([]);

  const createPDF = async (id, index) => {
    try {
      const loading = [];
      loading[index] = true;
      setloadingReport(loading);
      const progress = [];
      progress[index] = "0";
      setProgressReport(progress);
      const checklist = (await api.get(`checklist/${id}`)).data;
      const classes = (score) =>
        score === "Adequado"
          ? "#009870"
          : score === "Atenção"
          ? "#FFA800"
          : "#F64E60";

      const doc = new jsPDF();

      let body = [
        [
          {
            content: "Item",
            styles: { fillColor: "#F1F1F1", fontStyle: "bold" },
          },
          {
            content: "Avaliação",
            styles: { fillColor: "#F1F1F1", fontStyle: "bold" },
          },
        ],
      ];

      const params =
        checklist.checklistType === "Pasto" ? paramsPasture : paramsConfinement;

      params.forEach(({ param, label }) => {
        body.push([
          {
            content: label,
            styles: {
              textColor: "#495057",
              fillColor: "#ffffff",
            },
          },

          {
            content: "       " + (checklist[param].score || ""),
            styles: {
              textColor: classes(checklist[param].score),
              fillColor: "#ffffff",
            },
          },
        ]);
      });
      autoTable(doc, {
        theme: "grid",

        head: [
          [
            {
              content: `Relatório Gerencial - ${
                checklist.scenery.farmName
              } - ${checklist}  ${convertDateToString(checklist.date)}`,
              colSpan: 2,
              styles: {
                halign: "center",
                fillColor: "#F1F1F1",
                textColor: "#495057",
              },
            },
          ],
          [
            {
              content: "1.Painel de Gestão a vista, Macro",
              colSpan: 2,
              styles: { halign: "center", fillColor: "#FC8535" },
            },
          ],
        ],
        body,
        didDrawCell: (data) => {
          const text = data.cell.text[0] ? data.cell.text[0].trim() : "";
          if (
            data.section === "body" &&
            data.column.index === 1 &&
            text !== "Avaliação" &&
            text
          ) {
            const index = text === "Corrigir" ? 0 : text === "Atenção" ? 1 : 2;
            var base64Img = base64Icons[index];
            doc.addImage(
              base64Img,
              "JPEG",
              data.cell.x + 1,
              data.cell.y + 1,
              6,
              6
            );
          }
        },
      });
      body = [
        [
          {
            content: "Item",
            styles: { fillColor: "#F1F1F1", fontStyle: "bold", cellWidth: 60 },
          },
          {
            content: "Avaliação",
            styles: { fillColor: "#F1F1F1", fontStyle: "bold", cellWidth: 30 },
          },
          {
            content: "Comentários",
            styles: { fillColor: "#F1F1F1", fontStyle: "bold" },
          },
          /*  {
            content: "Imagens",
            styles: { fillColor: "#F1F1F1", fontStyle: "bold", cellWidth: 54 },
          }, */
        ],
      ];
      let totalImages = 0;
      for (let i = 0; i < params.length; i++) {
        const key = params[i].param;
        for (let j = 0; j < params[i].items.length; j++) {
          const param = params[i].items[j].param;

          if (
            checklist[key].evaluation[param] &&
            checklist[key].evaluation[param].images &&
            checklist[key].evaluation[param].images[0]
          ) {
            for (
              let k = 0;
              k < checklist[key].evaluation[param].images.length;
              k++
            ) {
              totalImages++;
            }
          }
        }
      }
      let imageIndex = 0;
      for (let i = 0; i < params.length; i++) {
        const key = params[i].param;
        for (let j = 0; j < params[i].items.length; j++) {
          const param = params[i].items[j].param;

          if (
            checklist[key].evaluation[param] &&
            checklist[key].evaluation[param].images &&
            checklist[key].evaluation[param].images[0]
          ) {
            for (
              let k = 0;
              k < checklist[key].evaluation[param].images.length;
              k++
            ) {
              imageIndex++;
              progress[index] = convertNumberToString(
                (imageIndex / totalImages) * 100,
                0
              );

              setProgressReport(progress);
              document.getElementById("root").click();
              if (!!checklist[key].evaluation[param].images[k].fileName) {
                checklist[key].evaluation[param].images[k].base64 =
                  await getBase64FromUrl(
                    `https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab-bovinos/${checklist._id}-${checklist[key].evaluation[param].images[k].fileName}`
                  );
              }
            }
          }
        }
      }

      params.forEach(({ param, label, items }) => {
        body.push([
          {
            content: label,
            //colSpan: 4,
            colSpan: 3,
            styles: { fontStyle: "bold", fillColor: "#F1F1F1" },
          },
        ]);

        items.forEach(async (item) => {
          let sumLines = 1;

          const hasImages =
            checklist[param].evaluation[item.param] &&
            checklist[param].evaluation[item.param].images &&
            checklist[param].evaluation[item.param].images[0];
          if (hasImages) {
            let indexImg = 0;
            checklist[param].evaluation[item.param].images.forEach(
              (item, index) => {
                if (indexImg === 4) {
                  sumLines++;
                  indexImg = 0;
                }
                indexImg++;
              }
            );
          }
          if (checklist[param].evaluation[item.param]) {
            body.push([
              {
                content: item.label,
                rowSpan: hasImages ? 2 : 1,
                styles: {
                  textColor: "#495057",
                  fillColor: "#ffffff",
                },
              },

              {
                content:
                  "       " +
                  (checklist[param].evaluation[item.param].score || ""),
                styles: {
                  textColor: classes(
                    checklist[param].evaluation[item.param].score
                  ),
                  fillColor: "#ffffff",
                },
              },
              {
                content:
                  (checklist[param].evaluation[item.param].justification ||
                    checklist[param].evaluation[item.param].relevance ||
                    "") +
                  (checklist[param].evaluation[item.param].action
                    ? " (Ação: " +
                      checklist[param].evaluation[item.param].action +
                      ")"
                    : ""),

                styles: {
                  textColor: "#495057",
                  fillColor: "#ffffff",
                },
              },
              /* {
              content: "",
              images: checklist[param].evaluation[item.param].images,
              styles: {
                textColor: "#495057",
                fillColor: "#ffffff",
                minCellHeight:
                  checklist[param].evaluation[item.param].images &&
                  checklist[param].evaluation[item.param].images[0]
                    ? sumLines * 27
                    : 0,
              },
            }, */
            ]);
            if (hasImages) {
              body.push([
                {
                  colSpan: 2,
                  content: "",
                  images: checklist[param].evaluation[item.param].images,
                  styles: {
                    textColor: "#495057",
                    fillColor: "#ffffff",
                    minCellHeight:
                      checklist[param].evaluation[item.param].images &&
                      checklist[param].evaluation[item.param].images[0]
                        ? sumLines * 30
                        : 0,
                  },
                },
              ]);
            }
          }
        });
      });

      autoTable(doc, {
        theme: "grid",
        //pageBreak: "always",
        head: [
          [
            {
              content: "2.Painel de Gestão à vista, Micro",
              //colSpan: 4,
              colSpan: 3,
              styles: { halign: "center", fillColor: "#FC8535" },
            },
          ],
        ],
        body,
        didDrawCell: (data) => {
          const text = data.cell.text[0] ? data.cell.text[0].trim() : "";
          if (
            data.section === "body" &&
            data.column.index === 1 &&
            text !== "Avaliação" &&
            text
          ) {
            const indexImg =
              text === "Corrigir" ? 0 : text === "Atenção" ? 1 : 2;
            var base64Img = base64Icons[indexImg];
            doc.addImage(
              base64Img,
              "JPEG",
              data.cell.x + 1,
              data.cell.y + 1,
              6,
              6
            );
          } else if (data.row.raw[0].images && data.row.raw[0].images[0]) {
            let count = 0;
            let rowSum = 0;

            data.row.raw[0].images.forEach(({ base64, height, width }) => {
              if (count === 4) {
                count = 0;
                rowSum++;
              }
              const w = width > height ? 28 : (28 / height) * width;
              const h = height > width ? 28 : (28 / width) * height;

              if (base64) {
                doc.addImage(
                  base64 || null,
                  "JPEG",
                  data.cell.x + 1 + count * 30,
                  data.cell.y + 1 + rowSum * 30,
                  w,
                  h
                );
              }

              count++;
            });
          }
        },
      });

      body = [];
      params.forEach(({ param, label }) => {
        const getPercent = (value) => {
          const sum = [
            checklist[param].valueDepth,
            checklist[param].value1,
            checklist[param].value2,
            checklist[param].value3,
            checklist[param].value6,
          ].reduce((a, b) => +a + +b, 0);
          return (sum ? value / sum : 0) * 100;
        };
        const getCV = (wagon, mm) =>
          Math.round(
            (getStandartDeviation(checklist[param][wagon][mm]) /
              getAverage(checklist[param][wagon][mm])) *
              100 *
              100
          ) / 100;
        let obs = "";
        if (param === "mixingQuality") {
          obs = `CV Geral por Crivo
          \n • 19 mm: ${convertNumberToString(
            (getCV("wagonStart", "mm19") + getCV("wagonEnd", "mm19")) / 2,
            2
          )}%\n • 8 mm: ${convertNumberToString(
            (getCV("wagonStart", "mm8") + getCV("wagonEnd", "mm8")) / 2,
            2
          )}%\n • 4 mm: ${convertNumberToString(
            (getCV("wagonStart", "mm4") + getCV("wagonEnd", "mm4")) / 2,
            2
          )}%\n • Fundo: ${convertNumberToString(
            (getCV("wagonStart", "depth") + getCV("wagonEnd", "depth")) / 2,
            2
          )}%\n\nCV Geral, médias de todos os crivos: ${convertNumberToString(
            ((getCV("wagonStart", "mm19") + getCV("wagonEnd", "mm19")) / 2 +
              (getCV("wagonStart", "mm8") + getCV("wagonEnd", "mm8")) / 2 +
              (getCV("wagonStart", "mm4") + getCV("wagonEnd", "mm4")) / 2 +
              (getCV("wagonStart", "depth") + getCV("wagonEnd", "depth")) / 2) /
              4,
            2
          )}%`;
          body.push([
            {
              content: label,
              styles: {
                fillColor: "#F1F1F1",
                fontStyle: "bold",
              },
            },
          ]);
          body.push([
            {
              content: obs,
              styles: {
                textColor: "#495057",
                fillColor: "#ffffff",
                //halign: "center",
              },
            },
          ]);
        } else if (param === "grainProcessing") {
          obs = `Peneira 6 mm:\n • Valor Obtido: ${convertNumberToString(
            checklist[param].value6
          )}g (${convertNumberToString(
            getPercent(checklist[param].value6),
            2
          )}%)\n • Valor Referência: 0%\n\nPeneira 3,25 mm:\n • Valor Obtido: ${convertNumberToString(
            checklist[param].value3
          )}g (${convertNumberToString(
            getPercent(checklist[param].value3),
            2
          )}%)\n • Valor Referência: 30-40%\n\nPeneira 2 mm:\n • Valor Obtido: ${convertNumberToString(
            checklist[param].value2
          )}g (${convertNumberToString(
            getPercent(checklist[param].value2),
            2
          )}%)\n • Valor Referência: 20-30%\n\nPeneira 1,25 mm:\n • Valor Obtido: ${convertNumberToString(
            checklist[param].value1
          )}g (${convertNumberToString(
            getPercent(checklist[param].value1),
            2
          )}%)\n • Valor Referência: 10-20%\n\nPeneira Fundo:\n • Valor Obtido: ${convertNumberToString(
            checklist[param].valueDepth
          )}g (${convertNumberToString(
            getPercent(checklist[param].valueDepth),
            2
          )}%)\n • Valor Referência: <10%\n\n`;
          body.push([
            {
              content: label,
              styles: {
                fillColor: "#F1F1F1",
                fontStyle: "bold",
              },
            },
          ]);
          body.push([
            {
              content: obs,
              styles: {
                textColor: "#495057",
                fillColor: "#ffffff",
                //halign: "center",
              },
            },
          ]);
        } else if (param === "stoolScore") {
          obs = `Score 1: ${convertNumberToString(
            checklist[param].evaluation.s1.distribution
          )}%\n\nScore 2: ${convertNumberToString(
            checklist[param].evaluation.s2.distribution
          )}%\n\nScore 3: ${convertNumberToString(
            checklist[param].evaluation.s3.distribution
          )}%\n\nScore 4: ${convertNumberToString(
            checklist[param].evaluation.s4.distribution
          )}%\n\nScore 5: ${convertNumberToString(
            checklist[param].evaluation.s5.distribution
          )}%\n\n`;
          body.push([
            {
              content: label,
              styles: {
                fillColor: "#F1F1F1",
                fontStyle: "bold",
              },
            },
          ]);
          body.push([
            {
              content: obs,
              styles: {
                textColor: "#495057",
                fillColor: "#ffffff",
                //halign: "center",
              },
            },
          ]);
        }
      });
      autoTable(doc, {
        theme: "grid",
        //pageBreak: "always",
        head: [
          [
            {
              content: "3.Detalhamento dos Resultados",
              styles: { halign: "center", fillColor: "#FC8535" },
            },
          ],
        ],
        body,
      });

      doc.save(
        `Relatório Gerencial - ${
          checklist.scenery.farmName
        } -  ${convertDateToString(checklist.date).replace(/\//g, "-")}.pdf`
      );
      setloadingReport([]);
    } catch (err) {
      setloadingReport([]);
      Swal.fire("Erro", err.message, "error");
      console.log(err);
    }
  };

  const tableParams = [
    {
      key: "date",
      label: "Data da Visita",
      filter: "date",
      type: "date",
      mobile: {
        type: "title",
      },
    },
    {
      key: "scenery",
      params: "farmName",
      label: "Nome da Fazenda",
      filter: "text",
      placeholder: "Digite o nome da fazenda",
      mobile: {
        type: "title",
      },
    },
    {
      key: "user",
      params: "name",
      label: "Usuário",
      filter: "text",
      placeholder: "Digite o nome do usuário",
      mobile: {
        type: "title",
      },
    },

    {
      key: "score",
      label: "Avaliação",
      filter: "select",
      options: ["Incompleto", "Corrigir", "Atenção", "Adequado"].map(
        (value) => ({ value })
      ),
      type: "custom",
      response: ({ score }) =>
        score ? (
          <Alert color={score.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}>
            {score}
          </Alert>
        ) : null,
      mobile: {
        type: "text",
      },
    },
    {
      key: "report",
      label: "Relatório",
      type: "custom",
      width: 100,
      response: ({ _id }, index) => (
        <Button
          bg="success"
          border="success"
          color="white"
          disabled={loadingReport[index]}
          onClick={() => createPDF(_id, index)}
        >
          {loadingReport[index] ? (
            `(${
              progressReport[index] === "100" ? "99" : progressReport[index]
            }%)Aguarde...`
          ) : (
            <Icons type="lineChart" color="white" size={14} />
          )}
        </Button>
      ),
      mobile: {
        type: "text",
      },
    },
  ];

  const paramsConfinement = [
    {
      param: "office",
      label: "Escritório",
      items: [
        { param: "daysInConfinement", label: "1.Dias em confinamento x Dieta" },
        { param: "pvp", label: "2.Peso Vivo Projetado (PVP), kg" },
        { param: "pv", label: "3.CMS % Peso Vivo (PV)" },
      ],
    },
    {
      param: "factory",
      label: "Fábrica",
      items: [
        { param: "stock", label: "1.Estoque" },
        { param: "organization", label: "2.Organização " },
        { param: "storage", label: "3.Armazenamento" },
        {
          param: "pestsFungiImpurities",
          label: "4.Presença de pragas, fungos, impurezas",
        },
        {
          param: "dietRegister",
          label: "5.Vagão misturador: Cadastro de dieta",
        },
        {
          param: "orderOfInclusion",
          label: "6.Vagão misturador: Ordem de inclusão de ingredientes",
        },
        {
          param: "predictedVsPerformed",
          label: "7.Vagão misturador: previsto vs realizado/ingrediente",
        },
        { param: "mixingTime", label: "8.Vagão misturador: Tempo de mistura" },
      ],
    },
    {
      param: "treatmentHandling",
      label: "Manejo de Trato",
      items: [
        { param: "correctDiet", label: "1.Dieta correta e Curral Correto" },
        { param: "correctAmount", label: "2.Quantidade correta" },
        { param: "correctTime", label: "3.Horário Correto" },
        { param: "inTheRightWay", label: "4.Da maneira correta" },
        { param: "dietSegregation", label: "5. Segregação de dieta" },
      ],
    },
    {
      param: "grainProcessing",
      label: "Processamento de Grãos",
      items: [
        {
          param: "averageSizeAndParticlesDistribution",
          label: "1.Tamanho médio e distribuição das partículas",
        },
      ],
    },
    {
      param: "mixingQuality",
      label: "Qualidade de Mistura",
      items: [
        {
          param: "wagonStart",
          label: "1.Qualidade de mistura, início de vagão",
        },
        { param: "wagonEnd", label: "2.Qualidade de mistura, final de vagão" },
      ],
    },
    {
      param: "bulkyNDFfe",
      label: "FDNfe Volumoso",
      items: [
        {
          param: "forageParticleEffectiveness",
          label: "1. Efetividade da  partícula do volumoso",
        },
      ],
    },
    {
      param: "stoolScore",
      label: "Escore de Fezes",
      items: [
        { param: "s1", label: "Score 1" },
        { param: "s2", label: "Score 2" },
        { param: "s3", label: "Score 3" },
        { param: "s4", label: "Score 4" },
        { param: "s5", label: "Score 5" },
      ],
    },
    {
      param: "waterQuality",
      label: "Qualidade de Água",
      items: [
        { param: "waterQuality", label: "1. Qualidade de água" },
        { param: "troughArea", label: "2. Área de Bebedouro/Animal" },
        { param: "adequateAccess", label: "3. Acesso Adequedo" },
        { param: "color", label: "4. Cor" },
        { param: "smell", label: "5. Cheiro" },
      ],
    },
    {
      param: "animals",
      label: "Animais",
      items: [
        { param: "ECC", label: "1.ECC" },
        { param: "nasalDischarge", label: "2.Secreção nasal" },
        { param: "ruffledHair", label: "3.Pelo arrepiado" },
        { param: "dullFur", label: "4.Pelo opaco" },
        { param: "duringTheDeal", label: "5.Durante o trato" },
        {
          param: "animalsInBarnyardBackground",
          label: "6.Animais em fundo de curral",
        },
        { param: "ruminating", label: "7.Ruminando" },
        { param: "idleness", label: "8.Ócio" },
        { param: "hullProblems", label: "9.Problemas de Casco" },
        { param: "corralFloor", label: "10.Piso do curral" },
        { param: "mud", label: "11.Lama" },
        { param: "m2ox", label: "12.m²/boi" },
        { param: "troughAreaCmBull", label: "13.Área de cocho, cm/boi" },
        { param: "tractOfTreatment", label: "14.Pista de trato" },
      ],
    },
  ];

  const paramsPasture = [
    {
      param: "office",
      label: "Escritório",
      items: [
        { param: "daysInConfinement", label: "1.Dias em confinamento x Dieta" },
        { param: "pvp", label: "2.Peso Vivo Projetado (PVP), kg" },
        { param: "pv", label: "3.CMS % Peso Vivo (PV)" },
      ],
    },
    {
      param: "factory",
      label: "Fábrica",
      items: [
        { param: "stock", label: "1.Estoque" },
        { param: "organization", label: "2.Organização " },
        { param: "storage", label: "3.Armazenamento" },
        {
          param: "pestsFungiImpurities",
          label: "4.Presença de pragas, fungos, impurezas",
        },
        {
          param: "supplementFormulationRegistration",
          label: "5.Misturador: Cadastro da formulação do suplemento",
        },
        {
          param: "orderOfInclusion",
          label: "6.Misturador: Ordem de inclusão de ingredientes",
        },
        {
          param: "predictedVsPerformed",
          label: "7.Misturador: previsto vs realizado/ingrediente",
        },
        { param: "mixingTime", label: "8.Misturador: Tempo de mistura" },
        { param: "mixerGrounding", label: "9. Aterramento do misturador" },
        {
          param: "wearOfHelicoidsPast",
          label: "10. Desgaste dos helicoides/pas",
        },
        { param: "presenceOfMagnet", label: "11. Presença de imã" },
        {
          param: "capacityXHittingWeight",
          label: "12. Capacidade vs peso de batida",
        },
      ],
    },

    {
      param: "grainProcessing",
      label: "Processamento de Grãos",
      items: [
        {
          param: "averageSizeAndParticlesDistribution",
          label: "1.Tamanho médio e distribuição das partículas",
        },
      ],
    },
    {
      param: "pastureAndAnimals",
      label: "Pastos e Animais",
      items: [
        {
          label: "1. Solo exposto a incidência solar, chuvas",
          param: "soilExposedToSunlightAndRain",
        },
        {
          label: "2.Estrutura do dossel forrageiro",
          param: "structureOfTheForageCanopy",
        },
        {
          label: "3.Altura do dosser forrageiro em pastejo contínuio",
          param: "heightOfTheForageDossier",
        },
        {
          label:
            "4. Altura de entrada e saída dos animais em pastejo rotacionado",
          param: "heightOfEntryAndExitOfAnimals",
        },
        {
          label: "5. ECC dos animais vs manejo e altura de pasto",
          param: "ECC",
        },
      ],
    },

    {
      param: "stoolScore",
      label: "Escore de Fezes",
      items: [
        { param: "s1", label: "Score 1" },
        { param: "s2", label: "Score 2" },
        { param: "s3", label: "Score 3" },
        { param: "s4", label: "Score 4" },
        { param: "s5", label: "Score 5" },
      ],
    },
    {
      param: "trough",
      label: "Coho",
      items: [
        { param: "structure", label: "1. Estrutura de cocho" },
        { param: "area", label: "2. Área de cocho" },
        { param: "access", label: "3. Acesso ao cocho" },
        {
          param: "aspectOfTheSupplement",
          label: "4. Aspecto do suplemento no cocho",
        },
        {
          param: "behavior",
          label:
            "5. Qual o comportamento dos animais do lote no momento da salga",
        },
      ],
    },

    {
      param: "waterQuality",
      label: "Qualidade de Água",
      items: [
        { param: "waterQuality", label: "1. Qualidade de água" },
        { param: "troughArea", label: "2. Área de Bebedouro/Animal" },
        { param: "adequateAccess", label: "3. Acesso Adequedo" },
        { param: "color", label: "4. Cor" },
        { param: "smell", label: "5. Cheiro" },
      ],
    },
  ];

  const [modalShow, setModalShow] = useState(false);

  const [id, setId] = useState("");

  const editHandler = (row) => {
    setId(row._id);
    setModalShow(true);
  };

  return (
    <>
      {modalShow && (
        <CreateEditChecklist
          show={modalShow}
          setShow={setModalShow}
          location={props.location}
          id={id}
          match={props.match}
        />
      )}
      <SubHeader
        {...props}
        title="Checklists"
        route="checklist"
        breadcrumbs={[
          { label: "Cadastro Base" },
          {
            label: "Checklists",
          },
        ]}
        icon={<Icons type="checklist" size="24" color="#fff" />}
      />
      <Table
        cols={tableParams}
        editHandler={editHandler}
        emptyText={"Nenhum usuário localizado!"}
        route={"checklist"}
        {...props}
      />
    </>
  );
}
